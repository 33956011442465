<template>
  <b-card>
    <div>
      <validation-observer ref="deviceValidation">
        <b-form class="auth-login-form mt-2" @submit.prevent>
          <!-- email -->
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            :label="$t('generalSettings.device_name')"
          >
            <validation-provider
              #default="{ errors }"
              name="DeviceName"
              rules="required"
            >
              <b-form-input class="input-max-width" v-model="deviceName" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label-cols="4"
            label-cols-lg="2"
            :label="
              isRadar()
                ? $t('generalSettings.location')
                : $t('settings.device_id')
            "
          >
            <validation-provider
              #default="{ errors }"
              name="Location"
              rules="required"
            >
              <b-form-input class="input-max-width" v-model="location" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            v-if="!isRadar()"
            label-cols="4"
            label-cols-lg="2"
            :label="$t('settings.serial_num')"
          >
            <validation-provider #default="{ errors }" name="SerialNum">
              <b-form-input class="input-max-width" v-model="serial_number" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <div>
        <b-button @click="saveDevice" variant="primary">{{
          $route.params.id
            ? $t('generalSettings.update_device')
            : $t('generalSettings.add_device')
        }}</b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { BCard, BButton, BFormGroup, BFormInput, BForm } from 'bootstrap-vue';
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';

export default {
  data() {
    return {
      id: null,
      deviceName: '',
      location: null,
      serial_number: null,
      required,
    };
  },
  components: {
    BCard,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    ValidationProvider,
    ValidationObserver,
  },
  async mounted() {
    if (this.$route.params.id) {
      const response = await this.$store.dispatch(
        'devices/GET_DEVICE',
        this.$route.params.id,
      );
      const device = response.data;
      this.id = device.id;
      this.location = device.location;
      this.deviceName = device.name;
    }
  },
  methods: {
    isRadar() {
      return this.$route.params.type == 'radar';
    },
    async saveDevice() {
      const validation = await this.$refs.deviceValidation.validate();
      if (validation) {
        const response = await this.$store.dispatch(
          this.$route.params.id ? 'devices/UPDATE' : 'devices/SAVE_DEVICE',
          {
            id: this.$route.params.id,
            name: this.deviceName,
            location: this.location,
            type: this.$route.params.type,
            serialNumber: this.serial_number,
          },
        );
        if (response) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: this.$t('success'),
              icon: 'AlertTriangleIcon',
              variant: 'success',
              text: this.$route.params.id
                ? this.$t('update_success_descirption')
                : this.$t('generalSettings.device_created'),
            },
          });
          this.$router.push({
            name: this.$route.params.type + '-list',
            params: {
              type: this.$route.params.id ? 'cyclist' : this.$route.params.type,
            },
          });
        }
      }
    },
  },
};
</script>

<style>
.input-max-width {
  max-width: 500px;
}
</style>
