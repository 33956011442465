var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',[_c('validation-observer',{ref:"deviceValidation"},[_c('b-form',{staticClass:"auth-login-form mt-2",on:{"submit":function($event){$event.preventDefault();}}},[_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"2","label":_vm.$t('generalSettings.device_name')}},[_c('validation-provider',{attrs:{"name":"DeviceName","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-max-width",model:{value:(_vm.deviceName),callback:function ($$v) {_vm.deviceName=$$v},expression:"deviceName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"2","label":_vm.isRadar()
              ? _vm.$t('generalSettings.location')
              : _vm.$t('settings.device_id')}},[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-max-width",model:{value:(_vm.location),callback:function ($$v) {_vm.location=$$v},expression:"location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(!_vm.isRadar())?_c('b-form-group',{attrs:{"label-cols":"4","label-cols-lg":"2","label":_vm.$t('settings.serial_num')}},[_c('validation-provider',{attrs:{"name":"SerialNum"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-input',{staticClass:"input-max-width",model:{value:(_vm.serial_number),callback:function ($$v) {_vm.serial_number=$$v},expression:"serial_number"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3938426723)})],1):_vm._e()],1)],1),_c('div',[_c('b-button',{attrs:{"variant":"primary"},on:{"click":_vm.saveDevice}},[_vm._v(_vm._s(_vm.$route.params.id ? _vm.$t('generalSettings.update_device') : _vm.$t('generalSettings.add_device')))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }